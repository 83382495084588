<!--
 * @Author: your name
 * @Date: 2020-12-23 19:44:15
 * @LastEditTime: 2021-02-03 18:30:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\shareit\index.vue
-->
<template>
  <div class="allmsg" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="qrshareit">
      <div class="canclass">
        <qrcode
          :url="url"
          :iconurl="icon"
          :wid="88"
          :hei="88"
          :imgwid="20"
          :imghei="16"
        ></qrcode>
      </div>
      <div class="qrmsg">扫描二维码有惊喜</div>
    </div>
    <!-- <div @click="$router.push('/guide')" class="tuiclass">推广指南</div>
    <button @click="clickmeth" class="bton bt">去推广</button> -->
    <shareit ref="sharitid"></shareit>
    <div class="qrp"></div>
  </div>
</template>
<script>
import qrcode from "vue_qrcodes";
import shareit from "../../components/shareit/index";
import base from "../../../util/base"
export default {
  components: {
    qrcode,
    shareit,
  },
  
  methods:{
  },
  data() {
    return {
      url:
        base.url+"/register?invitecode=" +
        this.$route.query.msg,
      icon: require("../../assets/users3.png"),
      msg: "邀请有礼",
    };
  },
};
</script>
<style scoped>
.bt {
  margin-top: 0.16rem;
}
.tuiclass {
  color: #f55613;
  font-size: 0.36rem;
  line-height: 0.48rem;
  text-align: center;
}
.allmsg {
  padding-bottom: 2rem;
  background-color: #ffffff;
}
.qrshareit {
  margin: 0.44rem 0.34rem;
  height: 9.97rem;
  background-image: url("../../assets/shaire.png");
  background-size: 100% 100%;
  position: relative;
}
.canclass {
  position: absolute;
  bottom: 0.94rem;
  right: 0.58rem;
  padding: 0.1rem;
  background-color: #ffffff;
}
.qrmsg {
  position: absolute;
  font-size: 0.2rem;
  line-height: 0.28rem;
  right: 0.58rem;
  bottom: 0.51rem;
  color: #ffffff;
}
.qrp {
  margin-top: 0.76rem;
  font-size: 0.34rem;
  color: #333333;
}
.qrp p {
  text-align: center;
}
</style>